/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Provider } from "~/generated/graphql";
import { useAccountsOptions } from "~/hooks/useAccountsOptions";
import { ThresholdDataSelectItem } from "../types";

export function useSelectAccount() {
  const [selectedAccount, setSelectedAccount] =
    useState<ThresholdDataSelectItem>({ id: "", value: "" });

  const { accountsOptions, loading, error } = useAccountsOptions([
    Provider.Aws,
    Provider.Azure,
    Provider.Gcp,
  ]);

  const accounts = accountsOptions
    .filter((account) => account.providerType === "CLOUD")
    .map((account) => ({
      value: account.label,
      id: account.value,
    }));

  const onSelect = (account: ThresholdDataSelectItem) => {
    setSelectedAccount(account);
  };

  return {
    accounts,
    selectedAccount,
    loading,
    error,
    onSelect,
  };
}
