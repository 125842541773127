/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import {
  Label,
  theme,
  MultipleSelect,
  SelectButton,
  Text,
  FlexContainer,
  Box,
  Spacer,
} from "@nordcloud/gnui";
import { isNotNil } from "~/tools";
import { DATA_DESCRIPTION } from "../constants";
import {
  useHandleThresholdDataFilterTabs,
  useSelectProvider,
  useSelectAccount,
} from "../hooks";
import { ThresholdDataFilterTabs } from "../hooks/useHandleThresholdDataFilterTabs";
import { useSelectService } from "../hooks/useSelectService";
import { ThresholdDataSelectItem } from "../types";
import { ThresholdFormData, ThresholdFormFields } from "../validators";
import { RadioOptionList } from "./RadioOptionList";

export function ThresholdData() {
  const { tabs, selectedTab, onTabSelect } = useHandleThresholdDataFilterTabs();

  return (
    <FlexContainer
      css={{ width: "100%" }}
      direction="column"
      alignItems="flex-start"
    >
      <Label required name="Threshold Data" css={{ margin: 0 }} />
      <Text size="sm" color={theme.color.text.text03}>
        {DATA_DESCRIPTION}
      </Text>
      <TabSelector tabs={tabs} onTabSelect={onTabSelect} />
      <Spacer height={theme.spacing.spacing03} />
      <ListSwitch selectedTab={selectedTab} />
    </FlexContainer>
  );
}

type TabSelectorProps = {
  tabs: { name: ThresholdDataFilterTabs; label: string; isActive: boolean }[];
  onTabSelect: (tabName: ThresholdDataFilterTabs) => void;
};

function TabSelector({ tabs, onTabSelect }: TabSelectorProps) {
  return (
    <MultipleSelect>
      {tabs.map(({ name, label, isActive }) => (
        <SelectButton
          key={name}
          name={name}
          value={name}
          labelText={label}
          isActive={isActive}
          onClick={() => onTabSelect(name)}
        />
      ))}
    </MultipleSelect>
  );
}

function ListSwitch({ selectedTab }: { selectedTab: ThresholdDataFilterTabs }) {
  const { setValue } = useFormContext<ThresholdFormData>();

  const {
    providersList: providerList,
    onSelect: onSelectProvider,
    selectedProvider,
  } = useSelectProvider();

  const {
    selectedAccount,
    accounts,
    onSelect: onSelectAccount,
  } = useSelectAccount();

  const {
    selectedService,
    services,
    onSelect: onSelectService,
  } = useSelectService();

  const handleProviderSelect = (value?: ThresholdDataSelectItem) => {
    if (isNotNil(value)) {
      onSelectProvider(value);
      setValue(ThresholdFormFields.PROVIDERS, [value.id]);
    }
  };

  const handleAccountSelect = (value?: ThresholdDataSelectItem) => {
    if (isNotNil(value)) {
      onSelectAccount(value);
      setValue(ThresholdFormFields.ACCOUNTS, [value.id]);
    }
  };

  const handleServiceSelect = (value?: ThresholdDataSelectItem) => {
    if (isNotNil(value)) {
      onSelectService(value);
      setValue(ThresholdFormFields.SERVICES, [value.id]);
    }
  };

  const lists: Record<ThresholdDataFilterTabs, RadioListControllerProps> = {
    providers: {
      list: providerList,
      name: ThresholdFormFields.PROVIDERS,
      selectedItem: selectedProvider,
      onSelect: handleProviderSelect,
    },
    accounts: {
      list: accounts,
      name: ThresholdFormFields.ACCOUNTS,
      selectedItem: selectedAccount,
      isSearchable: true,
      onSelect: handleAccountSelect,
    },
    services: {
      list: services,
      name: ThresholdFormFields.SERVICES,
      selectedItem: selectedService,
      isSearchable: true,
      onSelect: handleServiceSelect,
    },
  };

  return (
    <Box boxStyle="lightGrey" pb={0}>
      <RadioListController {...lists[selectedTab]} />
    </Box>
  );
}

type RadioListControllerProps = {
  list: ThresholdDataSelectItem[];
  selectedItem: ThresholdDataSelectItem;
  isSearchable?: boolean;
  name: ThresholdFormFields;
  onSelect: (value?: ThresholdDataSelectItem) => void;
};

function RadioListController({
  list,
  selectedItem,
  name,
  isSearchable,
  onSelect,
}: RadioListControllerProps) {
  return (
    <Controller
      name={name}
      render={() => (
        <RadioOptionList
          searchable={isSearchable}
          optionSelected={selectedItem}
          optionsList={list}
          onSelect={onSelect}
        />
      )}
    />
  );
}
