/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getFirstItem } from "~/tools";
import { providersList } from "../constants";
import { ProviderListItem } from "../types";
import { ThresholdFormData, ThresholdFormFields } from "../validators";

const defaultProvider = providersList[0];

export function useSelectProvider() {
  const { control } = useFormContext<ThresholdFormData>();
  const change = useWatch({ control, name: ThresholdFormFields.PROVIDERS });

  const [selectedProvider, setSelectedProvider] =
    useState<ProviderListItem>(defaultProvider);

  const onSelect = (provider: ProviderListItem) => {
    setSelectedProvider(provider);
  };

  useEffect(() => {
    setSelectedProvider(
      providersList.find(({ id }) => id === getFirstItem(change)) ??
        defaultProvider
    );
  }, [change]);

  return {
    selectedProvider,
    providersList,
    onSelect,
  };
}
