/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { useDisclosure } from "~/hooks";
import { Threshold } from "../../types";

export function useThresholdSidebar() {
  const { open, close, isOpen } = useDisclosure();

  const [mode, setMode] = useState<"add" | "edit">("add");
  const [threshold, setThreshold] = useState<Threshold>();

  const openAdd = () => {
    setMode("add");
    open();
  };

  const openEdit = (selectedThreshold: Threshold) => {
    setThreshold(selectedThreshold);
    setMode("edit");
    open();
  };

  const title = mode === "add" ? "Add New Threshold" : "Update Threshold";

  return {
    threshold,
    mode,
    isOpen,
    title,
    openAdd,
    openEdit,
    close,
  };
}
