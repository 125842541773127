/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { useCloudServicesQuery } from "~/generated/graphql";
import { isNotNil } from "~/tools";
import { ThresholdDataSelectItem } from "../types";

export function useSelectService() {
  const [selectedService, setSelectedService] =
    useState<ThresholdDataSelectItem>({ id: "", value: "" });

  const { data: cloudServices, loading, error } = useCloudServicesQuery();

  const services = (cloudServices?.services ?? []).map((service) => ({
    value: service.name,
    id: service.id,
  }));

  const onSelect = (value: ThresholdDataSelectItem) => {
    if (isNotNil(value)) {
      setSelectedService(value);
    }
  };

  return {
    services,
    selectedService,
    loading,
    error,
    onSelect,
  };
}
